import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { useEffect, useState } from 'react';
import { TitleText } from '../orderDetail/Detail.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import * as S from '@app/components/orders/Orders.styles';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { detailOrder } from '@app/api/order.api';
import { getRoleUser } from '@app/services/localStorage.service';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseAlert } from '@app/components/common/BaseAlert/BaseAlert';
import {
  CloseOutlined,
  CopyOutlined,
  DollarOutlined,
  EditOutlined,
  EyeOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';

type formProps = {
  detail: detailOrder;
  checkStatus: { [key: string]: number };
  status: string[];
  setModalOpen: (value: boolean) => void;
  setEditModalOpen: (value: boolean) => void;
  handleCancelOrder: (uuid: string) => void;
  setModalEditFeeOpen: (isOpen: boolean) => void;
};

function render(detail: detailOrder) {
  switch (detail.status) {
    case 'REFUND_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#665DB6' }} message={'Refund Pending'} />;
    case 'REFUNDED':
      return <BaseAlert style={{ width: 'fit-content', background: '#4A41A1' }} message={'Refunded'} />;
    case 'CONFIRM_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#41A0D6' }} message={'Confirm Pending'} />;
    case 'CONFIRMED':
      return <BaseAlert style={{ width: 'fit-content', background: '#0C8ED8' }} message={'Confirmed'} />;
    case 'SHIPPED':
      return <BaseAlert style={{ width: 'fit-content', background: '#00A48A' }} message={'Shipped'} />;
    case 'IN_TRANSIT':
      return <BaseAlert style={{ width: 'fit-content', background: '#00A48A' }} message={'In Transit'} />;
    case 'IN_PRODUCTION':
      return (
        <BaseAlert
          style={{ width: 'fit-content', background: '#FFDE59' }}
          className={'statuss-in-product'}
          message={'In Production'}
        />
      );
    case 'CANCEL_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#FD4D5A' }} message={'Cancel Pending'} />;
    case 'CANCELED':
      return <BaseAlert style={{ width: 'fit-content', background: '#E02734' }} message={'Cancel'} />;
    case 'REPLACE_PENDING':
      return <BaseAlert style={{ width: 'fit-content', background: '#D483B8' }} message={'Replace Pending'} />;
    case 'REPLACED':
      return <BaseAlert style={{ width: 'fit-content', background: '#D8249A' }} message={'Replaced'} />;
    case 'DELIVERED':
      return <BaseAlert style={{ width: 'fit-content' }} type="success" message={'Delivered'} />;
    case 'RETURNED':
      return <BaseAlert style={{ width: 'fit-content' }} type="error" message={'Returned'} />;
    default:
      return (
        <BaseAlert
          style={{ width: 'fit-content', background: '#2E5C92' }}
          message={detail.status.toLowerCase().replace('_', ' ')}
        />
      );
  }
}

function InfoOrdersDetail({
  detail,
  checkStatus,
  status,
  setModalOpen,
  setEditModalOpen,
  handleCancelOrder,
  setModalEditFeeOpen,
}: formProps) {
  const [isSeller, setIsSeller] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [isShowCancelSeller, setIsShowCancelSeller] = useState(false);
  const [isShowEditFees, setIsShowEditFees] = useState(false);

  useEffect(() => {
    const role = getRoleUser();
    setIsSeller(role.includes('SELLER'));

    if (isSeller) {
      setIsShowEdit(checkStatus[detail.status] < checkStatus.CONFIRM_PENDING);
    } else {
      setIsShowEdit(checkStatus[detail.status] < checkStatus.CONFIRMED);
    }

    setIsShowCancelSeller(isSeller && detail.status == 'PENDING');
    setIsShowEditFees(!isSeller && checkStatus[detail.status] < checkStatus.CONFIRMED);
  }, [detail]);

  return (
    <>
      <TitleText level={2}>Infomation</TitleText>
      <BaseCard padding={'2rem'} autoHeight={false}>
        <BaseRow gutter={[20, 24]}>
          <BaseCol xs={24} lg={12}>
            <S.GeneralInfo>
              <p>
                <b>Order Id:</b>
                <div>{detail.orderId}</div>
              </p>
              {!isSeller && (
                <p>
                  <b>Owner:</b>
                  <div>{detail.ownerEmail}</div>
                </p>
              )}
              <p>
                <b>Source:</b>
                <div>{detail.source.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())}</div>
              </p>
              <p style={{ alignItems: 'center' }}>
                <b>Number Of Units:</b>
                <div>{detail.products.reduce((numberUnits, product) => numberUnits + product.quantity, 0)}</div>
              </p>
              <p style={{ alignItems: 'center' }}>
                <b>Status:</b>
                <div>{render(detail)}</div>
              </p>
            </S.GeneralInfo>
          </BaseCol>
          <BaseCol xs={24} lg={12}>
            <S.GeneralInfo>
              <p>
                <b>Shipping Name:</b>
                <div>{detail.shippingName}</div>
              </p>
              <p>
                <b>Shipping Address:</b>
                <div>{detail.shippingAddress}</div>
              </p>
              <p>
                <b>Shipping Phone:</b>
                <div>{detail.shippingPhone || '-'}</div>
              </p>
              {detail.trackingId && (
                <p style={{ alignItems: 'center' }}>
                  <b>Tracking Id:</b>
                  <div>
                    <S.Tracking className={detail.trackingLabel ? 'has-tracking-label' : ''}>
                      <p>{detail.trackingId}</p>
                      <BaseTooltip title="Copy this Tracking ID">
                        <BaseButton
                          size="small"
                          severity="success"
                          onClick={async () => {
                            navigator.clipboard.writeText(detail.trackingId || '');
                          }}
                        >
                          <CopyOutlined style={{ lineHeight: 0 }} />
                        </BaseButton>
                      </BaseTooltip>
                    </S.Tracking>
                  </div>
                </p>
              )}
              <p style={{ alignItems: 'center' }}>
                <b>Tracking label:</b>
                {detail.trackingLabel ? (
                  <Tooltip placement="bottom" title="View tracking label">
                    <BaseButton size="small" type="ghost" href={detail.trackingLabel} target='"_blank"'>
                      <EyeOutlined style={{ lineHeight: 0 }} />
                    </BaseButton>
                  </Tooltip>
                ) : (
                  '-'
                )}
              </p>
            </S.GeneralInfo>
          </BaseCol>
        </BaseRow>
        <BaseRow justify="start" gutter={[20, 24]}>
          {isShowEdit && (
            <BaseCol>
              <BaseButton
                type="primary"
                style={{ alignItems: 'center' }}
                icon={<EditOutlined />}
                onClick={() => setEditModalOpen(true)}
              >
                Edit
              </BaseButton>
            </BaseCol>
          )}
          {isShowCancelSeller && (
            <BaseCol>
              <BaseButton
                type="primary"
                style={{ alignItems: 'center' }}
                icon={<CloseOutlined />}
                danger
                onClick={() => handleCancelOrder(detail.uuid)}
              >
                Cancel
              </BaseButton>
            </BaseCol>
          )}
          {isShowEditFees && (
            <>
              <BaseCol>
                <BaseButton
                  type="primary"
                  style={{ alignItems: 'center' }}
                  icon={<DollarOutlined />}
                  onClick={() => setModalEditFeeOpen(true)}
                >
                  Edit Fees
                </BaseButton>
              </BaseCol>
            </>
          )}
          {!getRoleUser().includes('SELLER') && (
            <BaseCol>
              <BaseButton
                type="primary"
                style={{ alignItems: 'center' }}
                icon={<ScheduleOutlined />}
                onClick={() => setModalOpen(true)}
              >
                Approve
              </BaseButton>
            </BaseCol>
          )}
        </BaseRow>
      </BaseCard>
    </>
  );
}

export default InfoOrdersDetail;
