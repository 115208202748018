import styled from 'styled-components';
import { BasePagination } from '../common/BasePagination/BasePagination';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';

export const ModalContainer = styled.div``;

export const ModalMain = styled.div``;
export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 1rem;
  border-top: 1px solid #f0f0f0;
`;

export const AvatarTable = styled.img`
  aspect-ratio: 3/4;
  width: 5rem;
  object-fit: cover;
`;

export const UlSize = styled.ul`
  height: 5rem;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  list-style-type: none;
  text-transform: uppercase;
  padding-left: 0.5rem;
`;

export const ColorPreview = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  height: 5rem;
  overflow-y: scroll;
  span {
    border: 1px solid rgb(229, 231, 235);
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    background-color: var(--color);
  }
`;

export const ProductContainer = styled.div`
  .product-footer {
    margin-top: 0.5rem;
    display: flex;
    justify-content: end;
    button {
      min-width: 5vw;
    }
  }
  .ant-card-body {
    padding: 1rem;
  }
`;

export const ProductSkuSltContainer = styled.div`
  display: flex;
  align-items: center;

  .preCode {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(190, 192, 198);
    border-right: none;
    border-radius: 7px 0 0 7px;
    background-color: #eeeeee;
    padding: 11.405px;
    min-width: 10%;
  }
  input {
    border-radius: 0 7px 7px 0;
  }
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  .selected-color {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    max-height: 15rem;
    width: 100%;
    overflow-y: scroll;

    .list-container {
      height: fit-content;
      border: 1px solid rgb(229, 231, 235);
      padding: 0.5rem;
      border-radius: 0.5rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      .color-name {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      svg {
        cursor: pointer;
      }

      &:hover {
        svg {
          color: rgb(255 102 38);
        }
      }
    }
  }
  .color-picker {
    padding: 0.25rem;
    border-radius: 7px;
    border: 1px solid rgb(166, 170, 178);
    display: flex;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
    /* :focus {
      border-color: var(--ant-primary-color-hover);
    } */
  }
  .color-preview {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 5px;
    background-color: var(--color-picker);
  }
`;
export const ChooseColorContainer = styled.div`
  width: min-content;
  border-radius: 0.5rem;
  border: 1px solid rgb(166, 170, 178);

  .color-text {
    position: relative;
    padding: 0.5rem;
    font-weight: 500;
    .color-picker {
      .ant-input-group-addon {
        min-width: fit-content;
        width: auto;
        font-size: 1rem;
        /* padding: 0; */
        :last-child {
          position: absolute;
          background-color: white;
          border: 1px solid var(--border-base-color);
          border-radius: 7px;
          left: 11.87rem;
          z-index: 1;
          cursor: pointer;
        }
      }
      .ant-input {
        padding: 0;
        padding-left: 0.25rem;
        border-radius: 0 7px 7px 0;
        :focus {
          box-shadow: none;
        }
      }
    }
    .ant-form {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .ant-form-item-control-input {
        min-height: fit-content;
      }
    }

    /* span {
      top: 1rem;
      right: 0.95rem;
      position: absolute;
      cursor: pointer;
      border: 1px solid rgb(229, 231, 235);
      border-radius: 50%;
      height: 2rem;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 1rem;
        width: 1rem;
      }
    } */
  }
  .add-color {
    padding: 0 0.25rem;
    height: fit-content;
  }
  .react-colorful__pointer {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const MockupPositionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;

  .mockup-link {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mockup-icon {
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
`;

export const AvatarPrevireContainer = styled.div`
  background-color: #cccccc;
  position: relative;

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .delete-icon {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    top: 0rem;
    right: 0rem;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    svg {
      height: 100%;
      width: 100%;
    }
  }
`;
export const HeaderTitle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  span {
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 100%;
      width: 100%;
    }
  }
  h4,
  p {
    margin: 0;
    padding: 0;
    color: var(--primary-color);
  }
`;

export const PagingationCustom = styled(BasePagination)`
  .ant-pagination-item a {
    font-size: 1rem;
  }
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-select-selector {
    height: 2rem;
    line-height: 2rem;
  }
  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 2rem !important;
    line-height: 2rem;
  }
`;

export const ProductName = styled.h1`
  font-size: ${FONT_SIZE.xxxl};
  font-weight: ${FONT_WEIGHT.bold};
  margin: 0;
  padding: 0;
  text-transform: capitalize;
`;

export const TagElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0.75rem 0;
  .label {
    text-transform: capitalize;
    font-size: ${FONT_SIZE.lg};
    font-weight: ${FONT_WEIGHT.semibold};
  }
  .content {
    font-size: ${FONT_SIZE.md};
    font-weight: ${FONT_WEIGHT.regular};
  }
`;
export const SubElementWapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
export const SizesElement = styled(SubElementWapper)`
  .item {
    background-color: rgb(249 250 251);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    border: 1px solid rgb(219, 219, 219);
    border-radius: 0.375rem;
    cursor: pointer;
  }
  .active {
    border-color: var(--orange);
    color: var(--orange);
  }
`;
export const ColorItem = styled.div`
  background-color: rgb(249 250 251);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 0.375rem;
  .color-preview {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: var(--color);
  }
`;
export const Descriptions = styled.div`
  table {
    width: 100%;
    th,
    td {
      padding: 0.5rem;
      border: 1px solid var(--primary-color);
    }
  }
`;

export const UnitPriceContainer = styled.div`
  .ant-table-body::-webkit-scrollbar {
    /* display: none; */
    width: 0;
  }
  .select-size {
    width: 100%;
    .ant-space-item {
      width: 100%;
    }
  }
`;
