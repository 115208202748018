import React, { useEffect, useRef, useState } from 'react';
import { FilterIcon } from 'components/common/icons/FilterIcon';
import { SearchOverlay } from './searchOverlay/SearchOverlay/SearchOverlay';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { CategoryComponents } from '@app/components/header/components/HeaderSearch/HeaderSearch';
import { Btn, InputSearch } from '../HeaderSearch/HeaderSearch.styles';
import { useTranslation } from 'react-i18next';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { getProduct } from '@app/api/product.api';
import { Component } from '@app/constants/config/components';
import { SearchReplaceLink, getOrder, orderType } from '@app/api/order.api';
import { getRoleUser } from '@app/services/localStorage.service';

interface SearchOverlayProps {
  query: string;
  setQuery: (query: string) => void;
  data: CategoryComponents[] | null;
  isOverlayOpen: boolean;
  setOverlayOpen: (state: boolean) => void;
}

export const SearchDropdown: React.FC<SearchOverlayProps> = ({
  query,
  setQuery,
  data,
  isOverlayOpen,
  setOverlayOpen,
}) => {
  const [isFilterOpen, setFilterOpen] = useState(false);

  const [filteredResults, setFilteredResults] = useState<CategoryComponents[] | null>(data);

  const { t } = useTranslation();

  const [isSeller, setIsSeller] = useState(false);

  useEffect(() => {
    setOverlayOpen(!!query || isFilterOpen);

    const role = getRoleUser();
    setIsSeller(role.includes('SELLER'));
  }, [query, isFilterOpen, setOverlayOpen]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  return (
    <>
      <BasePopover
        {...((!!filteredResults || isFilterOpen) && { trigger: 'click', onOpenChange: setOverlayOpen })}
        overlayClassName="search-overlay"
        content={<SearchOverlay data={filteredResults} isFilterOpen={isFilterOpen && filteredResults != null} />}
        open={isOverlayOpen}
        getPopupContainer={() => ref.current}
      >
        <HeaderActionWrapper>
          <InputSearch
            width="100%"
            value={query}
            placeholder={t('header.search')}
            filter={
              <Btn
                size="small"
                type={isFilterOpen ? 'ghost' : 'text'}
                aria-label="Filter"
                icon={<FilterIcon />}
                onClick={() => setFilterOpen(!isFilterOpen)}
              />
            }
            onChange={(event) => setQuery(event.target.value)}
            enterButton={null}
            addonAfter={null}
            onKeyDown={async (event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                const productRes = await getProduct({
                  page: 0,
                  size: 5,
                  s: query,
                });
                const orderRes = await getOrder({
                  page: 0,
                  size: 5,
                  searchText: query,
                });
                const mockupItem: Component[] = [];
                const designItem: Component[] = [];
                if (!isSeller) {
                  const replaceLinkRes = await SearchReplaceLink(query);
                  if (replaceLinkRes.designUrl != null) {
                    designItem.push({
                      name: replaceLinkRes.designUrl,
                      title: replaceLinkRes.designUrl,
                      url: replaceLinkRes.designUrl,
                    });
                  }
                  if (replaceLinkRes.mockupUrl != null) {
                    mockupItem.push({
                      name: replaceLinkRes.mockupUrl,
                      title: replaceLinkRes.mockupUrl,
                      url: replaceLinkRes.mockupUrl,
                    });
                  }
                }

                const productItems: Component[] = productRes.content.map<Component>((product) => ({
                  name: product.name,
                  title: product.name,
                  url: `/catalog/product/detail-product/${product.uuid}`,
                }));
                const orderItems: Component[] = orderRes.content.map((item: orderType) => ({
                  name: item.orderId,
                  title: item.orderId,
                  url: `/orders/detail/${item.uuid}`,
                }));

                if (isSeller) {
                  setFilteredResults([
                    {
                      category: 'products',
                      components: productItems,
                    },
                    {
                      category: 'orders',
                      components: orderItems,
                    },
                  ]);
                } else {
                  setFilteredResults([
                    {
                      category: 'products',
                      components: productItems,
                    },
                    {
                      category: 'orders',
                      components: orderItems,
                    },
                    {
                      category: 'design',
                      components: designItem,
                    },
                    {
                      category: 'mockup',
                      components: mockupItem,
                    },
                  ]);
                }
              }
            }}
          />
          <div ref={ref} />
        </HeaderActionWrapper>
      </BasePopover>
    </>
  );
};
