import React, { useEffect, useMemo, useState } from 'react';
import { BaseButton } from '../BaseButton/BaseButton';
import { BaseInput } from '../inputs/BaseInput/BaseInput';
import { BaseCheckbox } from '../BaseCheckbox/BaseCheckbox';
import * as S from './Filter.styles';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { BasePopoverProps } from '../BasePopover/BasePopover';

export type optionsType = {
  label: string;
  values: string[];
};
export type checkedListsType = {
  [key: string]: string[];
};

type FilterProps = {
  options: optionsType[];
  onReset: () => void;
  onOk: (options: checkedListsType) => void;
  isMultipleSelect?: boolean;
} & BasePopoverProps;

const BaseFilter: React.FC<FilterProps> = ({ options, onReset, onOk, content, isMultipleSelect, ...rest }) => {
  const [stOptions, setStOptions] = useState<optionsType[]>(options);
  const [search, setSearch] = useState('');
  const [checkedLists, setCheckedLists] = useState<checkedListsType>({});
  const [isDisabled, setIsDisabled] = useState(true);

  const filterSearch = useMemo(() => {
    return options.map((option) => ({
      ...option,
      values: option.values.filter((value) => value.toLowerCase().includes(search.toLowerCase())),
    }));
  }, [options, search]);

  useEffect(() => {
    if (search === '') {
      setStOptions(options);
    } else {
      setStOptions(filterSearch);
    }
  }, [search, filterSearch, options]);

  useEffect(() => {
    setIsDisabled(Object.values(checkedLists).every((value) => value.length === 0));
  }, [checkedLists]);
  const onChange = (label: string, list: string[]) => {
    // setCheckedLists((prevState) => ({ ...prevState, [label]: list }));
    setCheckedLists((prevState) => {
      if (isMultipleSelect == false) {
        return { ...prevState, [label]: list.slice(-1) };
      } else {
        return { ...prevState, [label]: list };
      }
    });
  };

  const onCheckAllChange = (label: string, checked: boolean) => {
    setCheckedLists((prevState) => ({
      ...prevState,
      [label]: checked ? options.find((option) => option.label === label)?.values || [] : [],
    }));
  };

  return (
    <div>
      <S.FilterContainer
        {...rest}
        placement="bottom"
        content={
          <>
            <div>
              <div>
                <BaseInput value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search in filter" />
              </div>
              <S.FilterMain className="filter-main">
                {stOptions.map((option) => (
                  <div className={`filter-cluster ${option.label}`} key={option.label}>
                    {isMultipleSelect == false ? (
                      <span>{option.label}</span>
                    ) : (
                      <BaseCheckbox
                        checked={checkedLists[option.label]?.length === option.values.length}
                        indeterminate={
                          !!checkedLists[option.label]?.length &&
                          checkedLists[option.label]?.length < option.values.length
                        }
                        onChange={(e) => onCheckAllChange(option.label, e.target.checked)}
                      >
                        {option.label}
                      </BaseCheckbox>
                    )}
                    <BaseCheckbox.Group
                      className="filter-group"
                      options={option.values}
                      value={checkedLists[option.label] || []}
                      onChange={(list: CheckboxValueType[]) => onChange(option.label, list.map(String))}
                    />
                  </div>
                ))}
                <>{content}</>
              </S.FilterMain>
              <S.FilterFooter>
                <BaseButton
                  onClick={() => {
                    setCheckedLists({});
                    onReset();
                  }}
                  className="reset-btn"
                  size="small"
                  type="text"
                  disabled={isDisabled}
                >
                  Reset
                </BaseButton>
                <BaseButton onClick={() => onOk(checkedLists)} size="small" type="primary">
                  Ok
                </BaseButton>
              </S.FilterFooter>
            </div>
          </>
        }
        trigger="click"
      >
        <BaseButton>
          Filter
          <svg
            width={24}
            height={24}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"
            />
          </svg>
        </BaseButton>
      </S.FilterContainer>
    </div>
  );
};

export default BaseFilter;
