import React, { useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import {
  Balance,
  BalanceAdmin,
  Customers,
  DashboardPageProps,
  Orders,
  Pending,
  Refunds,
  ToBePaidAmount,
  UnsolvedTickets,
} from '@app/components/nft-dashboard/Balance/Balance';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useTranslation } from 'react-i18next';
import { RecommendationProducts } from '@app/components/nft-dashboard/recommendationProducts/RecommendationProducts';
import { NewProducts } from '@app/components/nft-dashboard/newProducts/NewProducts';
import { References } from '@app/components/common/References/References';
import { getRoleUser } from '@app/services/localStorage.service';
import { OverviewUser, getOverviewUser, getUser } from '@app/api/user.api';

const AdminOverview: React.FC<DashboardPageProps> = ({ userOverview }) => {
  return (
    <BaseRow gutter={[20, 24]}>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Customers userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Orders userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <BalanceAdmin userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Refunds userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Pending userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <UnsolvedTickets userOverview={userOverview} />
      </BaseCol>
    </BaseRow>
  );
};

const SupportDashboard: React.FC<DashboardPageProps> = ({ userOverview }) => {
  return (
    <BaseRow gutter={[20, 24]}>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Orders userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Refunds userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Pending userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <UnsolvedTickets userOverview={userOverview} />
      </BaseCol>
    </BaseRow>
  );
};
const SellerDashboard: React.FC<DashboardPageProps> = ({ userOverview }) => {
  const [walletBalance, setWalletBalance] = useState<number>(0);
  useEffect(() => {
    getUser().then((user) => {
      setWalletBalance(user?.walletBalance || 0);
    });
  }, []);
  return (
    <BaseRow gutter={[20, 24]}>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Orders userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Refunds userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Pending userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <UnsolvedTickets userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <ToBePaidAmount userOverview={userOverview} walletBalance={walletBalance} />
      </BaseCol>
    </BaseRow>
  );
};
const WorkshopDashboard: React.FC<DashboardPageProps> = ({ userOverview }) => {
  return (
    <BaseRow gutter={[20, 24]}>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Orders userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <Pending userOverview={userOverview} />
      </BaseCol>
      <BaseCol xl={6} lg={8} md={8} sm={12}>
        <UnsolvedTickets userOverview={userOverview} />
      </BaseCol>
    </BaseRow>
  );
};
const innitUserOverview: OverviewUser = {
  orders: 0,
  refunds: 0,
  pending: 0,
  unsolvedTickets: 0,
  customers: 0,
  balance: 0,
  tobePaidAmount: 0,
};

const DashboardPage: React.FC = () => {
  const { isDesktop, isTablet } = useResponsive();
  const { t } = useTranslation();
  const [isSeller, setIsSeller] = useState(false);
  const [cards, setCards] = useState<React.ReactNode>();
  useEffect(() => {
    const fetch = async () => {
      let userOverview = innitUserOverview;
      try {
        userOverview = await getOverviewUser();
      } catch (error) {
        console.log('error', error);
      }
      const role = getRoleUser();
      if (role.includes('ADMIN')) {
        return setCards(<AdminOverview userOverview={userOverview} />);
      } else if (role.includes('SUPPORT')) {
        return setCards(<SupportDashboard userOverview={userOverview} />);
      } else if (role.includes('SELLER')) {
        setIsSeller(true);
        return setCards(<SellerDashboard userOverview={userOverview} />);
      } else if (role.includes('FACTORY')) {
        return setCards(<WorkshopDashboard userOverview={userOverview} />);
      }
    };
    fetch();
  }, []);

  const desktopLayout = (
    <>
      {!isSeller ? (
        <>
          <BaseRow gutter={[20, 24]} id="desktop-content">
            <BaseCol span={24}>{cards}</BaseCol>
          </BaseRow>
        </>
      ) : (
        <BaseRow>
          <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
            <BaseRow gutter={[60, 60]}>
              <BaseCol span={24}>{cards}</BaseCol>
              <BaseCol span={24}>
                <RecommendationProducts />
              </BaseCol>
            </BaseRow>
            <References />
          </S.LeftSideCol>

          <S.RightSideCol xl={8} xxl={7}>
            <div id="balance">
              <Balance />
            </div>
          </S.RightSideCol>
        </BaseRow>
      )}
    </>
  );

  const mobileAndTabletLayout = (
    <>
      {!isSeller ? (
        <>
          <BaseRow gutter={[20, 24]}>
            <BaseCol span={24}>{cards}</BaseCol>
          </BaseRow>
        </>
      ) : (
        <BaseRow gutter={[20, 24]}>
          <BaseCol span={isTablet ? 12 : 24}>
            <div id="overview">{cards}</div>
            <div id="balance">
              <Balance />
            </div>
          </BaseCol>

          <BaseCol span={24}>
            <RecommendationProducts />
          </BaseCol>
        </BaseRow>
      )}
    </>
  );

  return (
    <>
      <PageTitle>{t('common.dashboard')}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default DashboardPage;
