import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { Input } from '../orderDetail/Detail.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { detailOrder, trackingUrls } from '@app/api/order.api';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { useState } from 'react';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { ModalContainer, ModalFooter, ModalMain } from '@app/components/catalog/conmon.styles';

type formProps = {
  handleSubmit: (values: detailOrder) => void;
  detail: detailOrder;
  checkStatus: { [key: string]: number };
  status: string[];
  isOpen: boolean;
  onOpenChange: () => void;
};

function FormOrdersDetail({ handleSubmit, detail, checkStatus, status, isOpen, onOpenChange }: formProps) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <BaseCol span={24}>
        <BaseModal
          title={`Approve Order: ${detail.orderId}`}
          width={750}
          open={isOpen}
          onCancel={onOpenChange}
          footer={null}
          destroyOnClose
        >
          <BaseSpin spinning={loading}>
            <ModalContainer>
              <ModalMain>
                <BaseForm id={'detail-form'} layout="vertical" onFinish={handleSubmit} initialValues={detail}>
                  <BaseCol>
                    <BaseRow gutter={[20, 24]}>
                      <BaseCol lg={12} span={24}>
                        <BaseForm.Item label="Status" name="status">
                          <BaseSelect value={detail.status} defaultValue={detail.status} width={120}>
                            <Option value="" hidden>
                              {detail.status}
                            </Option>
                            {status.map((item: string) => (
                              <Option key={item} value={item}>
                                {item
                                  .toLowerCase()
                                  .replace('_', ' ')
                                  .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())}
                              </Option>
                            ))}
                          </BaseSelect>
                        </BaseForm.Item>
                      </BaseCol>

                      <BaseCol lg={12} span={24}>
                        <BaseForm.Item label="Tracking ID" name="trackingId">
                          <Input value={`${trackingUrls}${detail.trackingId || ''}`}></Input>
                        </BaseForm.Item>
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>
                </BaseForm>
              </ModalMain>
              <ModalFooter>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <BaseButton type="ghost" onClick={onOpenChange}>
                    Cancel
                  </BaseButton>
                  <BaseButton type="primary" htmlType="submit" form={'detail-form'}>
                    Approve
                  </BaseButton>
                </div>
              </ModalFooter>
            </ModalContainer>
          </BaseSpin>
        </BaseModal>
      </BaseCol>
    </>
  );
}

export default FormOrdersDetail;
