import { httpApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  fullname: string;
  email: string;
  password: string;
  timeZone: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface VerifyAccountPayload {
  verifyCode: string;
  otpId: string;
}

export interface NewPasswordData {
  password: string;
  resetId: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export type LoginResponse = {
  accessToken: string;
} & UserModel;
export type SignUpResponse = {
  otpId: string;
  email: string;
  fullName: string;
};

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
  httpApi.post<LoginResponse>('/api/v1/auth/login', { ...loginPayload }).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<SignUpResponse> =>
  httpApi.post<SignUpResponse>('/api/v1/auth/register', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi
    .post<undefined>('api/v1/auth/send-email-reset-password', { ...resetPasswordPayload })
    .then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const verifyAccount = (verifyAccountPayload: VerifyAccountPayload): Promise<undefined> =>
  httpApi.post<undefined>('/api/v1/auth/verify', { ...verifyAccountPayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('/api/v1/auth/reset-password', { ...newPasswordData }).then(({ data }) => data);
