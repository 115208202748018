import styled from 'styled-components';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { FONT_FAMILY, FONT_SIZE, LAYOUT, media } from '@app/styles/themes/constants';
import { BaseTypography } from '../common/BaseTypography/BaseTypography';

export const ModalContainer = styled.div``;

export const RightSideCol = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${LAYOUT.desktop.headerHeight});
  background-color: var(--layout-body-bg-color);
  overflow-y: auto;
`;

export const LeftSideCol = styled(BaseCol)`
  @media only screen and ${media.xl} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    height: calc(100vh - ${LAYOUT.desktop.headerHeight});
    overflow: auto;
  }
`;

export const Space = styled.div`
  margin: 1rem 0;
`;

export const TitleText = styled(BaseTypography.Title)`
  &.ant-typography {
    margin-bottom: 0.5rem;
    color: var(--text-main-color);

    font-size: ${FONT_SIZE.md};
  }
`;

export const TitleBalanceText = styled(BaseTypography.Title)`
  &.ant-typography {
    margin-bottom: 0;

    font-family: ${FONT_FAMILY.secondary};
  }
`;

export const TrackingButton = styled.div`
  .tracking-button {
    padding-top: ${FONT_SIZE.xxs} !important;
  }
`;

export const GeneralInfo = styled.div`
  > p {
    display: inline-flex;
    width: 100%;
    margin-bottom: 0.375rem;

    > b {
      width: 10rem;
    }

    > div {
      width: calc(100% - 10rem);
    }
  }
`;

export const GeneralInfoTable = styled.table`
  th {
    text-align: left;
    padding-right: 1rem;
  }

  .color-cricle {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: var(--color);
    border: 1px solid var(--border-nft-color);
  }
`;

export const PositionRowText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
`;

export const PositionRowLink = styled.a`
  margin-left: 0.25rem;
`;

export const PositionRow = styled(BaseCol)`
  display: flex;
  gap: 0.5rem;
`;

export const Tracking = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  p {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background: #eee;
    border-radius: 0.5rem;
    font-size: ${FONT_SIZE.xs};
  }

  &.has-tracking-label {
    p {
      background: var(--text-sider-primary-color);
    }
  }
`;
