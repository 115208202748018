import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import LayoutDetailPage from '@app/components/orders/orderDetail/DetailLayout';
import React from 'react';

function OrderDetailPage() {
  return (
    <>
      <PageTitle>Detail Order</PageTitle>
      <LayoutDetailPage></LayoutDetailPage>
    </>
  );
}

export default OrderDetailPage;
