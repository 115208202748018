import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface CKEditorWrapperProps {
  onChange?: (value: string) => void;
  value?: string;
}

export const CKEditorWrapper: React.FC<CKEditorWrapperProps> = ({ onChange, value }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange?.(data);
      }}
      config={{
        ui: {
          poweredBy: {
            label: '',
            side: 'right',
            position: 'border',
            verticalOffset: 0,
            horizontalOffset: 10000,
          },
        },
        placeholder: 'Descriptions',
      }}
      onReady={(editor) => {
        const root = editor.editing.view.document.getRoot();
        if (root) {
          editor.editing.view.change((writer) => {
            writer.setStyle('height', '200px', root);
          });
        }
      }}
    />
  );
};
