import React from 'react';
import { Layout } from '@app/components/terms-policy/TermsPolicy';

function PolicyPage() {
  const title = 'Privacy Policy';
  const title0 = '';
  const title1 = 'What information do we collect?';
  const title2 = 'What do we use your information for?';
  const title3 = 'How do we protect your information?';
  const title4 = 'Do we use cookies?';
  const title5 = 'Do we disclose any information to outside parties?';
  const title6 = 'California Online Privacy Protection Act Compliance';
  const title7 = "Children's Online Privacy Protection Act Compliance";
  const title8 = 'Online Privacy Policy Only';
  const title9 = 'Terms and Conditions';
  const title10 = 'Your Consent';
  const title11 = 'Changes to our Privacy Policy';
  const title12 = 'Contacting Us';

  const policy0 = `Protecting your private information is our priority. This Statement of Privacy applies to this web site and Embroidery Goods and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Embroidery Goods. By using this website, you consent to the data practices described in this statement.`;
  const policy1 = `We collect information from you when you place an order, subscribe to our newsletter, respond to a survey or fill out a form.

  When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address, phone number, or credit card information.`;
  const policy2 = `Any of the information we collect from you may be used in one of the following ways:

  -To process transactions
  
  Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.
  
  -To send periodic emails
  
  The email address you provide for order processing will only be used to send you information and updates pertaining to your order.
  
  Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.`;
  const policy3 = `We implement a variety of security measures to maintain the safety of your personal information when you place an order.

  We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems. They are required to keep the information confidential.

  After a transaction, your private information (credit cards, financials, etc.) will not be stored on our servers.`;
  const policy4 = `Yes (Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information.

  We use cookies to help us remember and process the items in your shopping cart, keep track of advertisements and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.`;
  const policy5 = `We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.`;
  const policy6 = `Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.

  As part of the California Online Privacy Protection Act, all users of our site may make any changes to their information anytime by using their unique member id and password in the Member Login section.`;
  const policy7 = `We are in compliance with the requirements of COPPA (Children's Online Privacy Protection Act). We do not collect any information from anyone under 13 years of age. Our website, products, and services are all directed to people who are at least 13 years old or older.`;
  const policy8 = `This online privacy policy applies only to information collected through our website and not to information collected offline.`;
  const policy9 = `Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website.`;
  const policy10 = `By using our site, you consent to this website's privacy policy.`;
  const policy11 = `If we decide to change our privacy policy, we will post those changes on this page.`;
  const policy12 = `If there are any questions regarding this privacy policy you may contact us.`;

  const data = [
    { title: title0, content: policy0 },
    { title: title1, content: policy1 },
    { title: title2, content: policy2 },
    { title: title3, content: policy3 },
    { title: title4, content: policy4 },
    { title: title5, content: policy5 },
    { title: title6, content: policy6 },
    { title: title7, content: policy7 },
    { title: title8, content: policy8 },
    { title: title9, content: policy9 },
    { title: title10, content: policy10 },
    { title: title11, content: policy11 },
    { title: title12, content: policy12 },
  ];

  return <Layout title={title} data={data}></Layout>;
}

export default PolicyPage;
