import React from 'react';
import * as S from '@app/components/common/BaseProductCard/BaseProductCard.styles';
import { ProductCard } from '@app/components/productCard/ProductCard';
import { ColorType, ResProduct } from '@app/api/product.api';

type ProductDataType = {
  color?: ColorType[];
} & ResProduct;

export interface ProductCardProps {
  items: ProductDataType[];
  preState?: string;
}

const CardItems: React.FC<ProductCardProps> = ({ items, preState }) => {
  return (
    <S.CardContainer>
      {items.map((item) => (
        <ProductCard key={item.uuid} productItem={item} preState={preState} />
      ))}
    </S.CardContainer>
  );
};

export default CardItems;
