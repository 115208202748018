import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as TP from './TermsPolicy.styles';

interface TermsLayoutProps {
  title: string;
  data: { title: string; content: string }[];
}

export const Layout: React.FC<TermsLayoutProps> = ({ title, data }) => {
  return (
    <>
      <PageTitle>{title}</PageTitle>
      <S.Wrapper>
        <S.BackgroundWrapper>
          <TP.Wrapper>
            <TP.ContentWrapper>
              <TP.Title>{title}</TP.Title>
              <TP.Hr></TP.Hr>
              {data.map((item, index) => (
                <div key={index}>
                  <h3>{item.title}</h3>
                  <p style={{ whiteSpace: 'pre-line' }}>{item.content}</p>
                </div>
              ))}
            </TP.ContentWrapper>
          </TP.Wrapper>
        </S.BackgroundWrapper>
      </S.Wrapper>
    </>
  );
};
