import { orderImport, urlTemplacre } from '@app/api/order.api';
import { ModalContainer, ModalFooter, ModalMain } from '@app/components/catalog/conmon.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import React, { useState } from 'react';
import { FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import { httpApi } from '@app/api/http.api';
import { notificationController } from '@app/controllers/notificationController';
import * as S from '@app/components/orders/Orders.styles';

const DraggerTitle = styled.div`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.bold};
`;
const DraggerDescription = styled.div`
  font-size: ${FONT_SIZE.md};
  padding: 0 1rem;
`;
const DraggerIconWrapper = styled.div`
  font-size: 4rem;
  color: var(--primary-color);
`;

interface OrderModalProps {
  isOpen: boolean;
  onOpenChange: () => void;
  onLoad: () => void;
}
const initImport: orderImport = {
  file: [],
};

interface errorData {
  orderId: string;
  errorMessage: string;
}
interface ListErrorData {
  message: string;
  status: string;
  listError?: errorData[];
}

export const OrderModal: React.FC<OrderModalProps> = ({ isOpen, onOpenChange, onLoad }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleImport = async (listCSV: any) => {
    try {
      const listError: ListErrorData[] = [];
      const dataError: errorData[] = [];

      await Promise.all(
        listCSV.fileList.map(async (fileInfo: { originFileObj: File }) => {
          const formData = new FormData();
          formData.append('file', fileInfo.originFileObj);
          const parserCsv = await httpApi.post('/api/v1/order/parser-csv', formData);
          if (parserCsv.data.length !== 0) {
            const batch = await httpApi.post('/api/v1/order/batch', parserCsv.data);
            if (batch.data.fail.length !== 0) {
              listError.push({
                message: 'File: ' + fileInfo.originFileObj.name,
                status: 'error',
                listError: batch.data.fail,
              });
              dataError.push(...batch.data.fail);
            } else {
              notificationController.success({ message: 'File: ' + fileInfo.originFileObj.name + ' is Imported' });
            }
          } else {
            notificationController.error({
              message: 'File: ' + fileInfo.originFileObj.name + ' not format for template!',
            });
          }
        }),
      );
      return [listError, dataError];
    } catch (error) {
      console.log('error', error);
      notificationController.error({ message: 'Import failed' });
      throw error;
    }
  };
  const downloadFile = async (dataError: errorData[]) => {
    try {
      let csvContent = 'Order Id, Error Message\n';
      dataError.forEach((error) => {
        csvContent += `${error.orderId}, ${error.errorMessage}\n`;
      });
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'error_data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      notificationController.success({ message: 'File download!' });
    } catch (error) {
      console.log('error', error);
      notificationController.error({ message: 'Download failed' });
      throw error;
    }
  };

  const handleSubmit = async (values: orderImport) => {
    setLoading(true);
    const handleData = await handleImport(values.file);
    const listError = handleData[0] as ListErrorData[];
    const dataError = handleData[1] as errorData[];
    setLoading(false);
    onOpenChange();
    if (listError.length !== 0) {
      BaseModal.error({
        title: 'Error Import',
        width: '40rem',
        content: (
          <BaseCol style={{ maxHeight: '20rem', overflowY: 'auto' }}>
            {listError.map((item, index) => {
              return (
                <div key={index}>
                  <p>{item.message}</p>
                  <BaseCol>
                    <S.GeneralInfoTable>
                      <tbody>
                        {item.listError?.map((error, index) => {
                          return (
                            <tr key={index}>
                              <th>Order Id</th>
                              <td>{`${error.orderId} : ${error.errorMessage}`}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </S.GeneralInfoTable>
                  </BaseCol>
                </div>
              );
            })}
            <BaseButton type="text" onClick={async () => await downloadFile(dataError)}>
              <svg
                style={{ transform: 'rotate(90deg)' }}
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 -960 960 960"
              >
                <path
                  fill="currentColor"
                  strokeWidth="0.999938"
                  strokeLinecap="round"
                  d="M480-120v-80h280v-560H480v-80h280q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H480Zm-80-160-55-58 102-102H120v-80h327L345-622l55-58 200 200-200 200Z"
                />
              </svg>
              Download File
            </BaseButton>
          </BaseCol>
        ),
      });
    }
    onLoad();
  };
  const uploadProps = {
    name: 'file',
    accept: '.csv',
    maxCount: 1,
    beforeUpload: () => {
      return false;
    },
  };
  const titleUse = 'How to use?';
  const titleContent = 'To use this feature correctly and effectively, please follow the instruction below.';
  const contentUse = [
    ` Clone/download the sample file (click "Download Template" link).`,
    ` Update the correct information according to the form provided.`,
    `  Export to CSV format (comma-separated).`,
    ` Upload the file, hit the "Import" button and wait for it to be processed, which usually doesn't take long.`,
  ];

  return (
    <BaseCol span={24}>
      <BaseModal title={`Import Order`} width={750} open={isOpen} onCancel={onOpenChange} footer={null} destroyOnClose>
        <BaseSpin spinning={loading}>
          <ModalContainer>
            <ModalMain>
              <BaseForm id={'category-form'} layout="vertical" onFinish={handleSubmit} initialValues={initImport}>
                <BaseRow gutter={[20, 24]}>
                  <BaseCol span={24}>
                    <BaseForm.Item
                      name="file"
                      label={'File Import'}
                      rules={[{ required: true, message: 'File is required' }]}
                    >
                      <BaseUpload.Dragger {...uploadProps} multiple={false}>
                        <DraggerIconWrapper>
                          <InboxOutlined />
                        </DraggerIconWrapper>
                        <DraggerTitle>{t('uploads.dragUpload')}</DraggerTitle>
                        <DraggerDescription style={{ textAlign: 'left' }}>
                          <span>{titleUse}</span>
                          <br />
                          <span>{titleContent}</span>
                          <ul style={{ listStyleType: 'decimal', paddingLeft: '2rem' }}>
                            {contentUse.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </DraggerDescription>
                      </BaseUpload.Dragger>
                    </BaseForm.Item>
                  </BaseCol>
                </BaseRow>
              </BaseForm>
              <a
                href={urlTemplacre}
                target="_blank"
                style={{ display: 'flex', alignItems: 'center', margin: '0 0 2rem 0' }}
                rel="noreferrer"
              >
                <svg
                  style={{ transform: 'rotate(90deg)' }}
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  viewBox="0 -960 960 960"
                >
                  <path
                    fill="currentColor"
                    strokeWidth="0.999938"
                    strokeLinecap="round"
                    d="M480-120v-80h280v-560H480v-80h280q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H480Zm-80-160-55-58 102-102H120v-80h327L345-622l55-58 200 200-200 200Z"
                  />
                </svg>
                Download Template
              </a>
            </ModalMain>
            <ModalFooter>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <BaseButton type="ghost" onClick={onOpenChange}>
                  Cancel
                </BaseButton>
                <BaseButton type="primary" htmlType="submit" form={'category-form'}>
                  Import
                </BaseButton>
              </div>
            </ModalFooter>
          </ModalContainer>
        </BaseSpin>
      </BaseModal>
    </BaseCol>
  );
};

export default OrderModal;
