import React from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Navigate } from 'react-router-dom';
import { WithChildrenProps } from '@app/types/generalTypes';
import { parseToken } from '@app/utils/utils';

export const RequireRole: React.FC<{ requiredRole: string[] } & WithChildrenProps> = ({ children, requiredRole }) => {
  let hasRole = false;

  const token = useAppSelector((state) => state.auth.token);
  if (token) {
    try {
      const parsedToken = parseToken(token);
      const roles = parsedToken.roles;
      if (requiredRole.some((item: string) => roles.includes(item.toUpperCase()))) {
        hasRole = true;
      }
    } catch (error) {
      console.error('Failed to parse token:', error);
    }
  }

  return hasRole ? <>{children}</> : <Navigate to="/" replace />;
};
