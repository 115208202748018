import { httpApi } from './http.api';

export type paramsType = {
  sort: string;
  page: number;
  size: number;
  s?: string;
};
export type dataType = {
  name: string;
  preCode: string;
  description: string;
};

export const getCategory = async (params: paramsType) => {
  const res = await httpApi.get('/api/v1/category/search', {
    params: params,
  });
  return res.data;
};
export const newCategory = (data: dataType) =>
  httpApi.post('/api/v1/category', data).then(({ data }) => {
    return data;
  });
export const updateCategory = (uuid: string, data: dataType) =>
  httpApi.put(`/api/v1/category/${uuid}`, data).then(({ data }) => {
    return data;
  });
