import { createGlobalStyle } from 'styled-components';
import { resetCss } from './resetCss';
import { BREAKPOINTS, FONT_SIZE, FONT_WEIGHT, media } from './themes/constants';
import {
  lightThemeVariables,
  darkThemeVariables,
  commonThemeVariables,
  antOverrideCssVariables,
} from './themes/themeVariables';

export default createGlobalStyle`

  ${resetCss}

  [data-theme='light'],
  :root {
    ${lightThemeVariables}
  }

  [data-theme='dark'] {
    ${darkThemeVariables}
  }

  :root {
    ${commonThemeVariables};
    ${antOverrideCssVariables};
  } 

  [data-no-transition] * {
    transition: none !important;
  }
  
  .range-picker {
    & .ant-picker-panels {
      @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px) {
        display: flex;
      flex-direction: column;
      }
    }
  }

  .search-overlay {
    box-shadow: var(--box-shadow);

    @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px)  {
      width: calc(100vw - 16px);
    max-width: 600px;
    }

    @media only screen and ${media.md} {
      width: 323px;
    }
  }

  a {
    color: var(--primary-color);
    &:hover,:active {
      color: var(--ant-primary-color-hover);
    }
  }
  
  .ant-picker-cell {
    color: var(--text-main-color);
  }

  .ant-picker-cell-in-view .ant-picker-calendar-date-value {
    color: var(--text-main-color);
    font-weight: ${FONT_WEIGHT.bold};
  }

  .ant-picker svg {
    color: var(--text-light-color);
  }

  // notifications start
  .ant-notification-notice {
    width: 18rem;
    padding: 0.5rem;
    /* min-height: 2.5rem; */
    .ant-notification-notice-with-icon{
      position: relative;
      padding-bottom: 0;
    }
    
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin-bottom: 0;
      margin-left: 2rem;
      line-height: 1;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-description {
      margin-left: 2rem;
      margin-top: 0;
    }

    .ant-notification-notice-icon {
      font-size: 2rem;
      margin-left: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-notification-notice-close {
      top: 0.5rem;
      right: 0.75rem;
    }

    .ant-notification-notice-close-x {
      display: flex;
      font-size: 0.9375rem;
    }    
    .title {
      font-size: ${FONT_SIZE.md};
      /* height: 3rem; */
      /* margin-left: 1.5rem; */
      display: flex;
      align-items: center;
      font-weight: ${FONT_WEIGHT.bold};

      &.title-only {
        overflow-wrap: anywhere;
        /* padding-bottom: 0.25rem; */
        padding-right: 0.25rem;
        color: var(--text-main-color);
        font-size: ${FONT_SIZE.md};
        /* height: 2rem;
        line-height: 2rem; */
        /* margin-left: 0.75rem; */
        font-weight: ${FONT_WEIGHT.semibold};
      }
  }
  
    .description {
      color: #404040;
      font-size: ${FONT_SIZE.xxs};
      font-weight: ${FONT_WEIGHT.regular};
      /* line-height: 1.375rem; */
    }
  
    &.ant-notification-notice-success {
      border: 1px solid var(--success-color);
      background: var(--notification-success-color);
      
      .title {
        color: var(--success-color);
      }
    }
  
    &.ant-notification-notice-info {
      border: 1px solid var(--primary-color);
      background: var(--notification-primary-color);
  
      .title {
        color: var(--primary-color);
      }
    }
  
    &.ant-notification-notice-warning {
      border: 1px solid var(--warning-color);
      background: var(--notification-warning-color);
  
      .title {
        color: var(--warning-color);
      }
    }
  
    &.ant-notification-notice-error {
      border: 1px solid var(--error-color);
      background: var(--notification-error-color);
  
      .title {
        color: var(--error-color);
      }
    }
  
    .success-icon {
      color: var(--success-color);
    }
  
    .info-icon {
      color: var(--primary-color);
    }
  
    .warning-icon {
      color: var(--warning-color);
    }
  
    .error-icon {
      color: var(--error-color);
    }
  }
  
  .ant-menu-inline, .ant-menu-vertical {
    border-right: 0;
  }
  .notification-without-description {
      padding-bottom: 0.5rem;
      .ant-notification-notice-close {
        top: 0.5rem ;
      }
      .ant-notification-notice-with-icon{
      padding-bottom: 0.25rem;
    }
      .ant-notification-notice-with-icon .ant-notification-notice-description  {
        margin-top: 0.625rem;
      }
    }
  // notifications end

  // ticket css start
    .newTicket-modal{
      .ant-row{
        row-gap: 0 !important;
      }
    }
    .ticket-detail-card{
      .ant-card-head-title{
        padding-top: 0;
      }
    }
  // ticket css end
  // product css start
    .position-table{
      table{
        padding-bottom: 4rem;
      }
    }
  // product css end
  .ant-popover-inner-content {
    padding: 0.5rem;
  }
  .ant-popover{
    z-index: 999;
  }
  // ant image preview start
  .ant-image-preview-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // ant image preview end
  .ant-image-mask{
    display: none;
  }
`;
