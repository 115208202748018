import styled from 'styled-components';
import { BORDER_RADIUS, FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 35%;
  top: 30%;
  transform: translate(-30%, -30%);
`;

export const ContentWrapper = styled.div`
  padding: 2rem;
  width: 90%;
  overflow: auto;
  background-color: rgba(var(--background-rgb-color), 0.93);
  border-radius: ${BORDER_RADIUS};

  @media only screen and ${media.xs} {
    padding: 2rem 1.25rem;
    width: 95%;
    max-height: calc(100vh - 3rem);
  }

  @media only screen and ${media.md} {
    padding: 2rem;
    width: 90%;
    max-height: calc(100vh - 3rem);
  }
`;

export const Title = styled.div`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.semibold};
  width: 80%;
  line-height: 1.375rem;

  color: var(--text-main-color);

  @media only screen and ${media.md} {
    font-size: ${FONT_SIZE.xxl};
  }
`;

export const Hr = styled.hr`
  margin-bottom: 2rem;
  margin-top: 0.5rem;
`;
