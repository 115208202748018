import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './BillingPage.style';
import * as NewsStyle from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { References } from '@app/components/common/References/References';
import { Balance } from '@app/components/nft-dashboard/Balance/Balance';
import { ColumnsType } from 'antd/es/table';
import {
  PaymentChannelModel,
  TopUpTransactionModel,
  changeStatus,
  getPaymentChannels,
  search,
} from '@app/api/topUp.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Pagination } from '@app/api/table.api';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { useMounted } from '@app/hooks/useMounted';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { EyeTwoTone } from '@ant-design/icons';
import { TopUpDetailModal } from './components/TopUpDetailModal';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { notificationController } from '@app/controllers/notificationController';
import { BaseAlert } from '@app/components/common/BaseAlert/BaseAlert';
import { getRoleUser } from '@app/services/localStorage.service';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 20,
  total: 0,
};

export interface TopUpApproveFormData {
  uuid: string;
  status: string;
  rejectNote?: string;
}

const BillingPage: React.FC = () => {
  const { isDesktop, isTablet } = useResponsive();
  const { isMounted } = useMounted();
  const { t } = useTranslation();
  const [needLoad, setNeedLoad] = useState<boolean>(true);
  const [valueSearch, setValueSearch] = useState<string>('');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [detailOrderUuid, setDetailOrderUuid] = useState<string>('');
  const [cards, setCards] = useState<PaymentChannelModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const columns: ColumnsType<TopUpTransactionModel> = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'center',
    },
    {
      title: 'Transaction Id',
      dataIndex: 'transactionId',
      render: (text: string) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'Payment',
      dataIndex: 'paymentChannel',
      align: 'center',
      render: (text: string) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount: number) => {
        return <span>$ {amount}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: string) => {
        switch (text) {
          case 'APPROVED':
            return <BaseAlert style={{ width: 'fit-content', background: '#00A48A' }} message={'Approved'} />;
          case 'REJECT':
            return <BaseAlert style={{ width: 'fit-content', background: '#E02734' }} message={'Reject'} />;
          default:
            return (
              <BaseAlert
                style={{ width: 'fit-content', background: '#FFDE59' }}
                className={'statuss-in-product'}
                message={text.toLowerCase()}
              />
            );
        }
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      render: (text: string) => {
        const content = new Date(text).toLocaleString();
        return <span>{content}</span>;
      },
    },
    // {
    //   title: 'Approved Date',
    //   dataIndex: 'approvedDate',
    //   render: (text: string) => {
    //     const content = text ? new Date(text).toLocaleString() : '-';
    //     return <span>{content}</span>;
    //   },
    // },
    {
      title: 'Action',
      dataIndex: 'uuid',
      align: 'center',
      render: (uuid: string) => {
        return (
          <>
            <BaseSpace style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', gap: '1rem' }}>
              <EyeTwoTone
                onClick={() => {
                  setDetailOrderUuid(uuid);
                  setModalOpen(true);
                }}
              />
            </BaseSpace>
          </>
        );
      },
    },
  ];

  const columnsAdmin: ColumnsType<TopUpTransactionModel> = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'center',
    },
    {
      title: 'Transaction Id',
      dataIndex: 'transactionId',
      render: (text: string) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'Owner',
      render: (row: TopUpTransactionModel) => {
        return (
          <span>
            {row.createdBy.email}
            <br></br>
            {row.createdBy.fullname}
          </span>
        );
      },
    },
    {
      title: 'Payment',
      dataIndex: 'paymentChannel',
      align: 'center',
      render: (text: string) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount: number) => {
        return <span>$ {amount}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: string) => {
        switch (text) {
          case 'APPROVED':
            return <BaseAlert style={{ width: 'fit-content', background: '#00A48A' }} message={'Approved'} />;
          case 'REJECT':
            return <BaseAlert style={{ width: 'fit-content', background: '#E02734' }} message={'Reject'} />;
          default:
            return (
              <BaseAlert
                style={{ width: 'fit-content', background: '#FFDE59' }}
                className={'statuss-in-product'}
                message={text.toLowerCase()}
              />
            );
        }
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      render: (text: string) => {
        const content = new Date(text).toLocaleString();
        return <span>{content}</span>;
      },
    },
    {
      title: 'Approved Date',
      dataIndex: 'approvedDate',
      render: (text: string) => {
        const content = text ? new Date(text).toLocaleString() : '-';
        return <span>{content}</span>;
      },
    },
    {
      title: 'Action',
      dataIndex: 'uuid',
      align: 'center',
      render: (uuid: string) => {
        return (
          <>
            <BaseSpace style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', gap: '1rem' }}>
              <EyeTwoTone
                onClick={() => {
                  setDetailOrderUuid(uuid);
                  setModalOpen(true);
                }}
              />
            </BaseSpace>
          </>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState<{
    data: TopUpTransactionModel[];
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const fetch = useCallback(
    async (pagination: Pagination) => {
      try {
        const res = await search(valueSearch, pagination.pageSize || 10, (pagination.current || 1) - 1);
        console.log('res', res);
        setTableData((tableData) => ({ ...tableData, loading: true }));
        if (isMounted.current) {
          setTableData((tableData) => ({
            ...tableData,
            loading: false,
            data: res.content.map((item: TopUpTransactionModel, index: number) => {
              const newItem = {
                ...item,
                no: res.number * res.size + index + 1,
              };
              return newItem;
            }),
            pagination: {
              total: res.totalElements,
              current: res.number + 1,
              pageSize: pagination.pageSize || 10,
            },
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setTableData((tableData) => ({ ...tableData, loading: false }));
      }
    },
    [valueSearch, isMounted],
  );

  useEffect(() => {
    if (needLoad) {
      fetch(initialPagination);
      setNeedLoad(false);
    }
    getPaymentChannels('en').then((res) => setCards(res));

    const role = getRoleUser();
    setIsAdmin(role.includes('ADMIN'));
  }, [fetch, needLoad]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleModal = () => setModalOpen((open) => !open);

  useEffect(() => {
    // setLoading(true);
    getPaymentChannels('en').then((res) => setCards(res));
    // .finally(() => setLoading(false));
  }, []);

  const onFinish = (values: TopUpApproveFormData) => {
    setLoading(true);
    console.log(values);
    changeStatus(values.uuid, values.status, values.rejectNote)
      .then(() => {
        setTimeout(() => {
          notificationController.success({ message: 'Approve success!' });
          setModalOpen(false);
        }, 100);
      })
      .finally(() => {
        setLoading(false);
        setNeedLoad(true);
      });
  };

  const tableTopUp = (
    <BaseCard padding={'0'}>
      <BaseRow style={{ padding: '1.25rem' }}>
        <NewsStyle.InputWrapper style={{ marginBottom: '0' }}>
          <NewsStyle.SearchIcon />
          <NewsStyle.Input
            placeholder={t('header.search')}
            value={valueSearch}
            onChange={(event) => {
              setValueSearch(event.target.value);
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                fetch(initialPagination);
              }
            }}
          />
        </NewsStyle.InputWrapper>
      </BaseRow>
      <BaseTable
        columns={isAdmin ? columnsAdmin : columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 1000 }}
      />
    </BaseCard>
  );

  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol xl={16} xxl={17} id="desktop-content">
        <BaseRow gutter={[60, 60]}>
          <BaseCol span={24}>{tableTopUp}</BaseCol>
        </BaseRow>
        <References />
      </S.LeftSideCol>

      <S.RightSideCol xl={8} xxl={7}>
        <div id="balance">
          <Balance />
        </div>
        <S.Space />
      </S.RightSideCol>
    </BaseRow>
  );

  const desktopLayoutAdmin = (
    <BaseRow>
      <S.LeftSideCol sm={24} id="desktop-content">
        <BaseRow gutter={[20, 24]}>
          <BaseCol span={24}>{tableTopUp}</BaseCol>
        </BaseRow>
        <References />
      </S.LeftSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      {!isAdmin && (
        <BaseCol span={isTablet ? 12 : 24}>
          <div id="balance">
            <Balance />
          </div>
        </BaseCol>
      )}
      <BaseCol span={24}>{tableTopUp}</BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>{t('common.dashboard')}</PageTitle>
      {isDesktop ? (isAdmin ? desktopLayoutAdmin : desktopLayout) : mobileAndTabletLayout}
      <BaseModal width={500} open={isModalOpen} onCancel={handleModal} footer={null} destroyOnClose>
        <BaseSpin spinning={loading}>
          <TopUpDetailModal uuid={detailOrderUuid} cards={cards} loading={loading} onFinish={onFinish} />
        </BaseSpin>
      </BaseModal>
    </>
  );
};

export default BillingPage;
