import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { UserModel, UserInfoModel } from '@app/domain/UserModel';
import { persistUser, persistUserInfo, readUser, readUserInfo } from '@app/services/localStorage.service';

export interface UserState {
  user: UserModel | null;
  userInfo: UserInfoModel | null;
}

const initialState: UserState = {
  user: readUser(),
  userInfo: readUserInfo(),
};

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  persistUser(newUser);

  return {
    payload: newUser,
  };
});

export const setUserInfo = createAction<PrepareAction<UserInfoModel>>('user/setUserInfo', (newUserInfo) => {
  persistUserInfo(newUserInfo);

  return {
    payload: newUserInfo,
  };
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default userSlice.reducer;
