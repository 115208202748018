export type CategoryType = 'design' | 'mockup' | 'products' | 'orders';

interface Category {
  name: CategoryType;
  title: string;
}

export const categoriesList: Category[] = [
  {
    name: 'products',
    title: 'common.products',
  },
  {
    name: 'orders',
    title: 'common.orders',
  },
  {
    name: 'design',
    title: 'common.design',
  },
  {
    name: 'mockup',
    title: 'common.mockup',
  },
];
