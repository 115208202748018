import { BORDER_RADIUS } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const ProductEditContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;
export const ProductEdit = styled.div`
  padding: 1rem;
  padding-top: 1.5rem;
  border-radius: ${BORDER_RADIUS};
  box-shadow: var(--box-shadow-nft-color);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const ProductEditData = styled.div`
  width: calc(100% - 4rem);
  display: flex;
  flex-wrap: wrap;
`;
