import React from 'react';
import { WithChildrenProps } from '@app/types/generalTypes';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  // let isAuthenticated = false;
  // const token = useAppSelector((state) => state.auth.token);
  // if (token) {
  //   try {
  //     const parsedToken = parseToken(token);
  //     const exp = parsedToken.exp;
  //     if (Math.round((exp * 1000 - Date.now()) / 1000) > 0) {
  //       isAuthenticated = true;
  //     }
  //   } catch (error) {
  //     console.error('Failed to parse token:', error);
  //   }
  // }

  // return isAuthenticated ? <>{children}</> : <Navigate to="/auth/login" replace />;
  return <>{children}</>;
};

export default RequireAuth;
