import { TopUpRequest } from '@app/components/nft-dashboard/Balance/interfaces/interfaces';
import { Page, httpApi } from './http.api';

export interface PaymentChannelModel {
  uuid: string;
  name: string;
  iconUri: string;
  description: string;
  lang: string;
}

export interface TopUpTransactionModel {
  uuid: string;
  transactionId: string;
  status: string;
  paymentChannel: string;
  createdBy: Owner;
  amount: number;
  createdDate: Date;
  approvedDate: Date;
}

export interface Owner {
  uuid: string;
  email: string;
  fullname: string;
}

export interface TopUpTransactionDetailModel {
  uuid: string;
  transactionId: string;
  evdUrl: string;
  rejectNote?: string;
  status: string;
  paymentChannel: string;
  amount: number;
  createdBy: string;
  createdDate: Date;
  approvedDate?: Date;
}

export const search = async (s: string, size: number, page: number): Promise<Page<TopUpTransactionModel>> => {
  const res = await httpApi.get<Page<TopUpTransactionModel>>('/api/v1/top-up/search', {
    params: {
      s: s,
      size: size,
      page: page,
    },
  });
  return res.data;
};

export const getPaymentChannels = async (lang: string): Promise<PaymentChannelModel[]> => {
  const res = await httpApi.get('/api/v1/top-up/payment-channel/all', {
    params: {
      lang: lang,
    },
  });
  return res.data;
};

export const topup = async (req: TopUpRequest): Promise<any> => {
  await httpApi.post('/api/v1/top-up', { ...req });
};

export const detail = async (uuid: string): Promise<TopUpTransactionDetailModel> => {
  const res = await httpApi.get('/api/v1/top-up/' + uuid, {});
  return res.data;
};

export const changeStatus = async (uuid: string, status: string, note?: string): Promise<any> => {
  const res = await httpApi.put(`/api/v1/top-up/${uuid}/change-status`, {
    status: status,
    note: note,
  });
  return res;
};
