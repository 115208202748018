import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';
import { FONT_FAMILY, FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const TitleText = styled(BaseTypography.Title)`
  &.ant-typography {
    margin-bottom: 1.25rem;
    color: var(--text-main-color);

    font-size: ${FONT_SIZE.md};
  }
`;

export const TitleBalanceText = styled(BaseTypography.Title)`
  &.ant-typography {
    margin-bottom: 0;

    font-family: ${FONT_FAMILY.secondary};
  }
`;

export const SubtitleBalanceText = styled(BaseTypography.Text)`
  font-family: ${FONT_FAMILY.secondary};

  font-size: ${FONT_SIZE.xs};

  font-weight: ${FONT_WEIGHT.regular};

  color: var(--text-nft-light-color);
`;
export const CardFooter = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;
  padding: 0.7125rem 0;
  .grow {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: ${FONT_SIZE.xxs};
    color: var(--green);
    svg {
      padding-bottom: 0.25rem;
      height: 1rem;
      width: 1rem;
      path {
        fill: var(--green);
      }
    }
  }
  .reduce {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: ${FONT_SIZE.xxs};
    color: var(--red);
    svg {
      padding-top: 0.125rem;
      height: 1rem;
      width: 1rem;
      transform: rotate(180deg);
      path {
        fill: var(--red);
      }
    }
  }
`;
