import { UserInfoModel, UserModel } from '@app/domain/UserModel';

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export interface VfACCType {
  mail: string;
  dateSend: string;
  otpId: string;
}
export const persistVerifyACC = (acc: VfACCType): void => {
  localStorage.setItem('verifyAccount', JSON.stringify(acc));
};
export const clearVerifyACC = (): void => {
  localStorage.removeItem('verifyAccount');
};
export const readVerifyACC = (): VfACCType | null => {
  const verifyAccount = localStorage.getItem('verifyAccount');

  return verifyAccount ? JSON.parse(verifyAccount) : null;
};
export const readToken = (): string => {
  return localStorage.getItem('accessToken') || '';
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const persistUserInfo = (user: UserInfoModel): void => {
  localStorage.setItem('userInfo', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : null;
};

export const readUserInfo = (): UserInfoModel | null => {
  const userStr = localStorage.getItem('userInfo');

  return userStr ? JSON.parse(userStr) : null;
};

export const getRoleUser = (): string[] => {
  const user = readUser();
  if (user) {
    return user.roles;
  }
  return [];
};
export const persistRemember = (isRemember: boolean): void => {
  localStorage.setItem('remember', isRemember.toString());
};
export const readRemember = (): boolean => {
  const remember = localStorage.getItem('remember');
  return remember ? JSON.parse(remember) : true;
};

export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');
export const deleteUserInfo = (): void => localStorage.removeItem('userInfo');
