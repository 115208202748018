import { httpApi } from './http.api';

export type paramsType = {
  sort?: string;
  page: number;
  size: number;
  s?: string;
};

export type dataType = {
  subject: string;
  content: string;
  status?: string;
  type: string | number;
  attachments?: File[] | string[];
};

export interface selectType {
  label: string;
  value: string | number;
}

export const TypeSelect: selectType[] = [
  {
    label: 'Other',
    value: 0,
  },
  {
    label: 'Order',
    value: 1,
  },
  {
    label: 'Top up',
    value: 2,
  },
];
export const StatusSelect = [
  {
    label: 'OPENING',
    value: 'OPENING',
  },
  {
    label: 'PROCESSING',
    value: 'PROCESSING',
  },
  {
    label: 'CLOSED',
    value: 'CLOSED',
  },
];

export const getTickets = async (params: paramsType) => {
  const res = await httpApi.get('/api/v1/ticket/search', {
    params: params,
  });
  return res.data;
};
export const newTicket = async (data: dataType) => {
  const res = await httpApi.post('/api/v1/ticket', data);
  return res;
};
export type ReplyType = {
  ticketUuid: string;
  content: string;
};
export const ReplyTicket = async (data: ReplyType) => {
  const res = await httpApi.post(`/api/v1/ticket/convention`, data);
  return res;
};
export const getTicketDetail = async (uuid: string) => {
  const res = await httpApi.get(`/api/v1/ticket/${uuid}`);
  return res.data;
};

export const UpdateStatusTicket = async (uuid: string, status: string) => {
  const res = await httpApi.put(`/api/v1/ticket/${uuid}`, { status: status });
  return res;
};
