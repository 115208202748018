import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { BaseCarousel } from '@app/components/common/BaseCarousel/Carousel';
import { NFTCardHeader } from '@app/components/nft-dashboard/common/NFTCardHeader/NFTCardHeader';
import { ViewAll } from '@app/components/nft-dashboard/common/ViewAll/ViewAll';
import { ProductCard } from '@app/components/productCard/ProductCard';
import { ResProduct, getProduct, paramsType } from '@app/api/product.api';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './RecommendationProducts.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useNavigate } from 'react-router-dom';
import { getRoleUser } from '@app/services/localStorage.service';

export const RecommendationProducts: React.FC = () => {
  const [products, setProducts] = useState<ResProduct[]>([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mobileOnly, isTablet } = useResponsive();

  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const role = getRoleUser();
    if (!role.includes('SELLER')) {
      setSlidesToShow(4);
    }

    const fetch = async () => {
      const params: paramsType = {
        page: 0,
        size: 10,
        isRecommend: true,
      };
      try {
        const data = await getProduct(params);
        setProducts(data.content);
      } catch (error) {
        console.log('error', error);
      }
    };

    fetch();
  }, []);

  const cards = useMemo(() => {
    return {
      mobile: products.slice(0, 3).map((product) => <ProductCard key={product.name} productItem={product} />),
      tablet: products.map((product) => (
        <div key={product.name}>
          <S.CardWrapper>
            <ProductCard productItem={product} />
          </S.CardWrapper>
        </div>
      )),
    };
  }, [products]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any>();

  return (
    <>
      <NFTCardHeader title={t('product.bestSellers')}>
        {isTablet && (
          <BaseRow align="middle">
            <BaseCol>
              <ViewAll bordered={false} onClick={() => navigate('catalog/product')} />
            </BaseCol>

            <BaseCol>
              <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickPrev()}>
                <LeftOutlined />
              </S.ArrowBtn>
            </BaseCol>

            <BaseCol>
              <S.ArrowBtn type="text" size="small" onClick={() => sliderRef.current && sliderRef.current.slickNext()}>
                <RightOutlined />
              </S.ArrowBtn>
            </BaseCol>
          </BaseRow>
        )}
      </NFTCardHeader>

      <S.SectionWrapper>
        {mobileOnly && cards.mobile}

        {isTablet && products.length > 0 && (
          <BaseCarousel
            ref={sliderRef}
            slidesToShow={slidesToShow}
            responsive={[
              {
                breakpoint: 1900,
                settings: {
                  slidesToShow: slidesToShow - 1,
                },
              },
            ]}
          >
            {cards.tablet}
          </BaseCarousel>
        )}
      </S.SectionWrapper>

      {mobileOnly && (
        <S.ViewAllWrapper>
          <ViewAll />
        </S.ViewAllWrapper>
      )}
    </>
  );
};
