import React, { useEffect, useState } from 'react';
import { getRoleUser } from '@app/services/localStorage.service';
import AdminProductPage from './AdminPage';
import SellerPage from './SellerPage';

const ProductPage: React.FC = () => {
  const [role, setRole] = useState<boolean>(false);

  useEffect(() => {
    const roles: string[] = getRoleUser();
    if (roles.includes('ADMIN') || roles.includes('SUPPORT')) {
      setRole(true);
    }
  }, []);

  return <>{role ? <AdminProductPage /> : <SellerPage />}</>;
};

export default ProductPage;
