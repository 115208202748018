import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { VerificationCodeInput } from '@app/components/common/inputs/VerificationCodeInput/VerificationCodeInput';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doVerifyVerifyAccount } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './VerifyAccountForm.styles';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { VfACCType, clearVerifyACC, readVerifyACC } from '@app/services/localStorage.service';

interface VerifyAccountFormProps {
  onBack?: () => void;
  onFinish?: () => void;
}

export const VerifyAccountForm: React.FC<VerifyAccountFormProps> = ({ onBack, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [verifyInfo, setVerifyInfo] = useState<VfACCType | null>();

  const navigateBack = useCallback(() => navigate(-1), [navigate]);
  const navigateForward = useCallback(() => navigate('/auth/login'), [navigate]);

  const [VerifyAccount, setVerifyAccount] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setVerifyInfo(readVerifyACC());
  }, []);

  useEffect(() => {
    if (VerifyAccount.length === 6) {
      const queryParams = new URLSearchParams(location.search);
      setLoading(true);
      dispatch(doVerifyVerifyAccount({ verifyCode: VerifyAccount, otpId: queryParams.get('otpId') as string }))
        .unwrap()
        .then(() => {
          notificationController.success({
            message: t('verifyAccountForm.verifySuccessMessage'),
            description: t('verifyAccountForm.verifySuccessDescription'),
          });
          clearVerifyACC();
          navigateForward();
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  }, [t, VerifyAccount, navigateForward, onFinish, dispatch, location.search]);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <Auth.BackWrapper onClick={onBack || navigateBack}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <S.ContentWrapper>
          <S.ImageWrapper>
            <BaseImage src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t('verifyAccountForm.title')}</Auth.FormTitle>
          <S.VerifyEmailDescription>{`${t('verifyAccountForm.verifCodeSent')} ${
            verifyInfo?.mail || t('verifyAccountForm.yourMail')
          }`}</S.VerifyEmailDescription>
          {isLoading ? <BaseSpin /> : <VerificationCodeInput autoFocus onChange={setVerifyAccount} />}
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
