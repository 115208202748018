import { UserInfoModel, UpdateUserInfoRequest, ChangeUserPasswordRequest } from '@app/domain/UserModel';
import { httpApi } from './http.api';
import { persistUserInfo, readUserInfo } from '@app/services/localStorage.service';

export const getUser = () =>
  httpApi.get<UserInfoModel>('/api/v1/user/info').then(({ data }) => {
    persistUserInfo(data);
    return data;
  });

export const updateUserInfo = (request: UpdateUserInfoRequest) =>
  httpApi.put<undefined | any>('/api/v1/user/info', { ...request }).then(({ data }) => {
    const userInfo = readUserInfo();
    if (userInfo != null) {
      userInfo.fullname = request.fullname;
      userInfo.address = request.address;
      userInfo.socials = request.socials;
      persistUserInfo(userInfo);
    }
    return data;
  });

export const changeUserPassword = (request: ChangeUserPasswordRequest) =>
  httpApi.put<undefined | any>('/api/v1/user/password', { ...request }).then(({ data }) => data);

export interface OverviewUser {
  balance: number;
  customers: number;
  orders: number;
  refunds: number;
  pending: number;
  unsolvedTickets: number;
  tobePaidAmount: number;
}

export const getOverviewUser = () => httpApi.get<OverviewUser>('/api/v1/analytics/overview').then(({ data }) => data);
