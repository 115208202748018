import React from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import * as S from './ProductCard.styles';
import { ColorType, ResProduct, minUnitPrice } from '@app/api/product.api';
import { useNavigate } from 'react-router-dom';

type ProductDataType = {
  color?: ColorType[];
} & ResProduct;

interface ProductCardProps {
  productItem: ProductDataType;
  preState?: string;
}

export const ProductCard: React.FC<ProductCardProps> = ({ productItem, preState }) => {
  const { isTablet } = useResponsive();
  const navigate = useNavigate();

  const tabletLayout = (
    <>
      <S.InfoHeader>
        <S.InfoText>@{productItem.productSku}</S.InfoText>
      </S.InfoHeader>

      <S.InfoFooter>
        <S.CurrentBidWrapper>
          <S.CurrentBid>Color</S.CurrentBid>
          <S.Bid>
            {productItem.color &&
              productItem.color
                .slice(0, 6)
                .map((color) => (
                  <span
                    title={color.name}
                    className={`color-cricle ${productItem.color && productItem.color?.length > 6 ? '' : 'mt-5px'}`}
                    key={color.hex}
                    style={{ '--color': color.hex } as React.CSSProperties}
                  ></span>
                ))}
            {productItem.color && productItem.color?.length > 6 ? `+${productItem.color?.length - 6}` : ``}
          </S.Bid>
        </S.CurrentBidWrapper>

        <S.CurrentBidWrapper>
          <S.Bid>
            {getCurrencyPrice(formatNumberWithCommas(minUnitPrice(productItem.unitPrices)), CurrencyTypeEnum.USD)}
          </S.Bid>
        </S.CurrentBidWrapper>
      </S.InfoFooter>
    </>
  );

  const mobileLayout = (
    <>
      <S.InfoRow>
        <S.InfoText>@{productItem.productSku}</S.InfoText>
        <S.Bid>
          {getCurrencyPrice(formatNumberWithCommas(minUnitPrice(productItem.unitPrices)), CurrencyTypeEnum.USD)}
        </S.Bid>
      </S.InfoRow>

      <S.InfoRow>
        <S.CurrentBid></S.CurrentBid>
        <S.Bid>
          {productItem.colors &&
            productItem.colors.map((color) => (
              <span
                title={color.name}
                className="color-cricle"
                key={color.hex}
                style={{ '--color': color.hex } as React.CSSProperties}
              ></span>
            ))}
        </S.Bid>
      </S.InfoRow>
    </>
  );

  return (
    <S.Card
      padding={0}
      $img={productItem.avatar as string}
      onClick={() => navigate(`/catalog/product/detail-product/${productItem.uuid}${preState ? `?${preState}` : ''}`)}
    >
      <S.NftImage src={productItem.avatar as string} alt={productItem.name} />
      <S.NftInfo>
        <S.InfoRow>
          <S.Title>{productItem.name}</S.Title>
        </S.InfoRow>
        {isTablet ? tabletLayout : mobileLayout}
      </S.NftInfo>
    </S.Card>
  );
};
